/* #999 */

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    color: #999;
}


.Login-component {
    /* The image used */
    background-image: url('../../assets/images/background.png');
  
    /* Full height */
    min-height: 100vh;
    width: 100%;
  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: unset;
  }
  
  .login-box {
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
      margin: 40px auto 60px;
      width: 100%;
      max-width: 400px;
      display: block;
  }
  
  .box-inside {
      background: #ffffff;
      padding: 20px;
      z-index: 20;
      box-shadow: 0 0 1px 0 #ccc;
      -webkit-box-shadow: 0 0 1px 0 #ccc;
      -moz-box-shadow: 0 0 1px 0 #ccc;
      border-radius: 10px;
  }
  
  .login-title {
    color: #8a4cff;
    font-size: 35px; 
    margin-bottom: 20px;
  }
  
  .frm-title {
    font-size: 12px;
    color: #999;
    text-align: center;
    padding-bottom: 20px;
    font-weight: bold
  }
  
  .forgotpassword {
    float: right;
    display: flex;
    margin-top: 9px;
  }
  
  .unlockicon {
    margin-right: 4px;
    margin-top: 5px;
  }
  .lefticon {
    margin-left: 5px;
  }
  
  .login-checkbox label {
    margin-left: -50px;
    font-size: 13px;
    color: #999;
  }
  
  .login-checkbox {
    margin-top: 10;
  }
  
  .forgot-text {
    color: #999;
    font-size: 1rem;
    
  }
  
  .nastext{
    color: #061238;
  }
  .frm-footer{
    margin-top: 30px;
    border-top: 1px solid #ccc;
    color: #999;
    font-size: 14px;
    padding: 20px 0px 0px 0px;
  }

  .css-1o2jng6-MuiOutlinedInput-notchedOutline{
      border-color: rgba(0, 0, 0, 0.23)!important;
  }

  .login-textfield .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root{
      padding-left: 20px!important;
  }

  .login-textfield .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      padding-left: 20px!important;
  }
  .css-cktaik {
    padding-left: 20px !important;
  }
  .posBtn{
    margin-top: 20px;
    background-color: #fff;
    color: #8a4cff;
    text-decoration: none;
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #8a4cff;
  }

  .modal-dialog{
    display: flex;
    justify-content: center;
  }
