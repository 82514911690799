.setting-para {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
} 

.spinner {
    animation: spin infinite 5s linear;
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.recharts-cartesian-grid-horizontal line{
  stroke: unset !important;
}
.recharts-cartesian-grid-vertical line {
  stroke: unset !important;
}
.recharts-line path{
  stroke-width: 3 !important
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ffffff 67%,
    #ffffff 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.btn-shine{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 12px 48px;
  color: #ffffff;
  background: linear-gradient(to right, #ffffff 0, #6f9a9b 10%, #fffafa 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  margin-top: 20px;
} 
@keyframes shine{
  0%{
    background-position: 0;
  }
    
  60%{
    background-position: 180px;
  }
  
  100%{
    background-position: 360px;
  }
   
}

.recharts-tooltip-wrapper-top{
  width: 120px !important
}

.recharts-tooltip-wrapper-bottom{
  width: 120px !important
}

.allin {
  font-size: 10px;
  color: rgb(91, 255, 3);
  font-weight: bold;
  /* border-right: 1px solid #bebee0; */
  margin-top: 15px;
}
.allout {
  font-size: 10px;
  color: rgb(255, 0, 0);
  font-weight: bold;
  /* border-left: 1px solid #bebee0; */
  margin-top: 15px;
}
.allin2 {
  font-size: 14px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  margin-top: 15px;
  text-align: start;
}
.allout2 {
  font-size: 14px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  margin-top: 15px;
  text-align: end;
}